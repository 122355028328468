import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CheckAccount = () => {
    const [progress1, setProgress1] = React.useState(0);
    const [progress2, setProgress2] = React.useState(0);
    const [progress3, setProgress3] = React.useState(0);
    const [progress4, setProgress4] = React.useState(0);
    const { t } = useTranslation();

    const navigate = useNavigate();
    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress1((oldProgress) => {
                if (oldProgress === 100) {
                    // setTimeout(()=>navigate('/risingstar'),1500);
                    return oldProgress;
                }
                const diff = Math.random() * 50;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {

        if (progress1 >= 100) {
            const timer = setInterval(() => {
                setProgress2((oldProgress) => {
                    if (oldProgress === 100) {
                        // setTimeout(()=>navigate('/risingstar'),1500);
                        return oldProgress;
                    }
                    const diff = Math.random() * 50;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };

        }

    }, [progress1])

    useEffect(() => {
        if (progress2 >= 100) {
            const timer = setInterval(() => {
                setProgress3((oldProgress) => {
                    if (oldProgress === 100) {
                        // setTimeout(()=>navigate('/risingstar'),1500);
                        return oldProgress;
                    }
                    const diff = Math.random() * 50;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        }

    }, [progress2])

    useEffect(() => {
        if (progress3 >= 100) {
            const timer = setInterval(() => {
                setProgress4((oldProgress) => {
                    if (oldProgress === 100) {
                        setTimeout(() => navigate('/risingstar'), 1000);
                        return oldProgress;
                    }
                    const diff = Math.random() * 50;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);

            return () => {
                clearInterval(timer);
            };
        }
    }, [progress3])

    return (
        <Box height='100vh'>
            <Box fontSize='30px' mb='45vh' >
                { t('check.title')}
            </Box>
            <Box my='1em'>
                <Box display='flex' justifyContent='space-between'>
                    {t("check.age")}
                    <CheckCircleOutlineIcon sx={{ color: progress1 === 100 ? '#CC4B50' : 'white' }} />
                </Box>
                <LinearProgress color='inherit' variant="determinate" value={progress1} sx={{ color: '#CC4B50' }} />
            </Box>
            <Box my='1em'>
                <Box display='flex' justifyContent='space-between'>
                    {t("check.level")}
                    <CheckCircleOutlineIcon sx={{ color: progress2 === 100 ? '#CC4B50' : 'white' }} />
                </Box>
                <LinearProgress color='inherit' variant="determinate" value={progress2} sx={{ color: '#CC4B50' }} />
            </Box>
            <Box my='1em'>

                <Box display='flex' justifyContent='space-between'>
                    {t("check.premium")}
                    <CheckCircleOutlineIcon sx={{ color: progress3 === 100 ? '#CC4B50' : 'white' }} />
                </Box>
                <LinearProgress color='inherit' variant="determinate" value={progress3} sx={{ color: '#CC4B50' }} />
            </Box>
            <Box my='1em'>

                <Box display='flex' justifyContent='space-between'>
                    {t("check.og")}
                    <CheckCircleOutlineIcon sx={{ color: progress4 === 100 ? '#CC4B50' : 'white' }} />
                </Box>
                <LinearProgress color='inherit' variant="determinate" value={progress4} sx={{ color: '#CC4B50' }} />
            </Box>
        </Box>
    )
}

export default CheckAccount
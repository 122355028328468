import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import no1 from '../images/no1.png'
import no2 from '../images/no2.png'
import no3 from '../images/no3.png'
import BottomNav from '../components/BottomNav';
import { axiosInstance } from '../api';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function formatNumber(num) {
    try{
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return num.toString();
    }
    catch(err){
        console.error(err);
        return num
    }
    
}

const WallOfFame = () => {

    const {t} = useTranslation()
    const [items, setItems] = useState([]);
    const [rank,setRank] = useState(0);
    const [total,setTotal] = useState(0);

    const balance = useSelector(state=>state.wooool.balance);
    const username = useSelector(state=>state.wooool.username);
    const tg_id = useSelector(state=>state.wooool.tgId);
    //   const items = Array.from({ length: 30 }, (_, index) => index);
    useEffect(() => {
        axiosInstance.post('/user/index', { page: 1, page_size: 30 }).then(ret=>{
            console.log('[debug]user list:',ret.data);
            setTotal(ret.data.data.total);
            setItems(ret.data.data.list)
            return axiosInstance.post('/user/rank',{tg_id});
        }).then(ret=>{
            console.log('[debug]rank return data:',ret.data);
            setRank(ret.data?.data?.rank);
            return 
        });
    },[])

    const getRanking = (index) => {
        switch (index) {
            case 0:
                return <img src={no1} alt='' />;
            case 1:
                return <img src={no2} alt='' />;
            case 2:
                return <img src={no3} alt='' />;
            default:
                return `#${index + 1}`
        }
    }
    return (
        <Box >

            <Box fontSize='24px' mb='0.1em' textAlign='center' mt='0'>
                {t('list.title')}
            </Box>
            <Card sx={{
                bgcolor: '#1a1a1a', borderRadius: '0.75em', color: 'white', p: '0.5em', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '20px',
            }}>
                <Box display='flex' mt='0.5em'>
                    <Box>
                        <Avatar />
                    </Box>
                    <Box ml='0.5em'>
                        <Box fontSize='14px' mb='0.5em' textAlign='left' >
                            {username||tg_id}
                        </Box>
                        <Box fontSize='14px' mb='0.75em' textAlign='left' color='grey'>
                            {balance} {t("unit")}
                        </Box>
                    </Box>
                </Box>
                <Box fontSize='12px'>
                    #{rank}
                </Box>
            </Card>
            <Box fontSize='20px' fontWeight='bold' mt='1em' overflow='auto' pb='3em'>
                {formatNumber(Number(total))} {t("list.holders")}
                {items.map((item, index) => (
                    <Box
                        key={'ik_'+index}
                        display='flex'
                        mt='0.5em'
                        pt='0.5em'
                        alignItems='center'
                        justifyContent='space-between'
                    // border='1px solid yellow'
                    >
                        <Box display='flex'>
                            <Avatar src={item.avatar_full_url||''}/>
                            <Box ml='0.5em'>
                                <Box fontSize='14px' mb='0.5em' textAlign='left'>
                                    {item.username||(item.first_name+' '+item.last_name)}
                                </Box>
                                <Box fontSize='14px' mb='0.75em' textAlign='left' color='grey'>
                                    {item.balance} {t("unit")}
                                </Box>
                            </Box>
                        </Box>
                        <Box fontSize='12px'>
                            {getRanking(index)}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box sx={{ width: '100%', bgcolor: 'black' }}>
                <BottomNav value={1} />
            </Box>

        </Box >
    )
}

export default WallOfFame
import React, { useEffect, useState } from 'react'
import { SendTransactionRequest, useTonConnectUI, useTonWallet, TonConnectButton, useTonAddress } from "@tonconnect/ui-react";
import { Address } from '@ton/ton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FollowIcon from '@mui/icons-material/FollowTheSignsOutlined';
import DogSVG from '../images/dog.svg'
import TwitterIcon from '@mui/icons-material/Twitter';
import BottomNav from '../components/BottomNav';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../api';
import { useQuery } from '@tanstack/react-query';
import { base64url } from 'rfc4648';



import './sheep.css';
import { Drawer, Paper } from '@mui/material';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
var loadedGvar = false;


const shorten = (addr) => {
    return addr && (addr.slice(0, 4) + '...' + addr.slice(-4));
}

const Score = () => {
    const [showTonModal, setShowTonModal] = useState(false);
    const wooool = useSelector(state => state.wooool);
    const [loaded, setLoaded] = useState(loadedGvar);
    const balance = useSelector(state => state.wooool.balance);
    const inviteCode = useSelector(state => { return state.wooool.inviteCode });
    const tgId = useSelector(state => state.wooool.tgId)
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const [rewards, setRewards] = useState(null);

    const wallet = useTonWallet();
    const walletAddress = useTonAddress()
    console.log('[debug]wallet:', wallet);
    console.log('[debug]address:', wallet?.account?.address);

    const [tonConnectUi] = useTonConnectUI();

    const fetchRewards = async (tg_id) => {
        console.log('[]---->', tg_id);
        return axiosInstance.post('/balance_log/index', {
            tg_id,
            page: 1,
            page_size: 10
        }).then(ret => {
            return ret.data?.data;
        })

    }

    const { data: rewards, error, isLoading, refetch } = useQuery({
        queryKey: ['rewards'],
        queryFn: () => fetchRewards(tgId),
        enabled: !!tgId,
        refetchInterval: 10000
    });

    useEffect(() => {
        console.log('[debug]tgId:', tgId);
        if (tgId) {
            console.log('refetch');
            refetch();
        }
    }, [tgId, refetch])

    useEffect(() => {
        loadedGvar = true;
        setLoaded(true);
    }, [])

    useEffect(()=>{
        if(walletAddress&&tgId){
            axiosInstance.post('/user/bind_address',{
                tg_id:tgId,
                address:walletAddress
            }).catch(err=>{
                console.error('[debug]bind_address:',err);
            })
        }
    },[walletAddress,tgId])

    return (
        <Box height='100vh' sx={{ paddingBottom: '188px' }}>

            <Box
                onClick={() => {
                    navigate('/risingstar')
                }}
                sx={{
                    backgroundColor: '#CC4B50', fontSize: '15px', mb: '1em',
                    textAlign: 'center', p: '0.5em', display: 'flex',
                    justifyContent: 'center', alignItems: 'center'
                }}>
                <PlayCircleOutlineOutlinedIcon /> {t('score')}
            </Box>

            <Box mt='20px' justifyContent='center' display='flex' position='relative'>
                <img src={DogSVG} className={'transition-sheep ' + (loaded ? 'shrink' : '')} alt='' />
            </Box>
            <Box fontSize='30px' mb='0.1em' textAlign='center' mt='0'>
                {balance} {t('name')}
            </Box>
            {
                wallet ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    
                    <Button sx={{
                        bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%', mt: 2,
                        ':hover': {
                            backgroundColor: '#cc4b50',
                            color: 'white',
                        },
                    }}
                        onClick={() => {
                            // tonConnectUi.disconnect();
                            setShowTonModal(true);
                        }}
                    >
                        <Typography fontWeight='bold' fontSize='14px'>
                            {shorten(walletAddress)}
                        </Typography>
                    </Button>
                </Box> :
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button sx={{
                            bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%', mt: 2,
                            ':hover': {
                                backgroundColor: '#cc4b50',
                                color: 'white',
                            },
                        }}
                            onClick={() => {
                                try {
                                    tonConnectUi.openModal();
                                }
                                catch (err) {
                                    console.log(err);
                                }

                            }}
                        >
                            <Typography fontWeight='bold' fontSize='14px'>
                                {t("home.connect_wallet")}
                            </Typography>
                        </Button>
                    </Box>
            }
            <Card sx={{ bgcolor: '#1a1a1a', borderRadius: '0.75em', color: 'white', p: '1.5em', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '20px' }}>
                <Box fontSize='20px' mb='0.5em' textAlign='center'>
                    {t("home.community")}
                </Box>
                <Box fontSize='14px' mb='0.75em' textAlign='center' color='grey'>
                    {t("home.ogs")}
                </Box>
                <Button sx={{
                    bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%',
                    ':hover': {
                        backgroundColor: '#cc4b50',
                        color: 'white',
                    },
                }}
                    onClick={() => {
                        window.location.href = 'https://t.me/WoolsGlobal';
                    }}
                >
                    <Typography fontWeight='bold' fontSize='14px'>
                        {t("home.goto_channel")} +100
                    </Typography>
                </Button>

                <Button sx={{
                    bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%', mt: 2,
                    ':hover': {
                        backgroundColor: '#cc4b50',
                        color: 'white',
                    },
                }}
                    onClick={() => {
                        const a = window.open('https://x.com/woooolalaland?t=ZnqHoOnoYYiaVpDPo-9JOg&s=09', '_blank');
                        console.log('[debug]open twitter:', a);
                        setTimeout(() => {
                            tgId && axiosInstance.post('/user/twitter', { tg_id: tgId });
                        }, 500)
                    }}
                >
                    <Typography fontWeight='bold' fontSize='14px'>
                        {t("home.goto_twitter")} +100
                    </Typography>
                </Button>

                <Button sx={{
                    bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%', mt: 2,
                    ':hover': {
                        backgroundColor: '#cc4b50',
                        color: 'white',
                    },
                }}
                    onClick={() => {
                        const a = window.open('https://twitter.com/intent/tweet?url=https://t.me/WoolsGlobal&text=Free+USDT!%3f+WOOOOLALA%7e+Come+quick+and+join.+You+WOOOOL+not+regret', '_blank');
                        console.log('[debug]open Twitter on Share:', a);
                        setTimeout(() => {
                            tgId && axiosInstance.post('/user/share_on_twitter', { tg_id: tgId });
                        }, 500)
                    }}
                >
                    <Typography fontWeight='bold' fontSize='14px'>
                        {t("home.goto_ShareOnTwitter")} +100
                    </Typography>
                </Button>

            </Card>
            <Box fontSize='20px' fontWeight='bold' mt='1em'>
                {t("home.your_reward")}
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
                <Box display='flex' alignItems='center' fontSize='14px'>
                    <AccessTimeOutlinedIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
                    {t("home.age_reward")}
                </Box>
                <Box fontWeight='bold' fontSize='16px'>
                    +{rewards?.registration_reward} {t("name")}
                </Box>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
                <Box display='flex' alignItems='center' fontSize='14px'>
                    <DoneOutlinedIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
                    {t("premium")}
                </Box>
                <Box>
                    {rewards?.member_reward || 0}
                </Box>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
                <Box display='flex' alignItems='center' fontSize='14px'>
                    <AddIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
                    {t("Referral")}
                </Box>
                <Box>
                    {rewards?.referral_reward || 0}
                </Box>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
                <Box display='flex' alignItems='center' fontSize='14px'>
                    <FollowIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
                    {t("Follow")}
                </Box>
                <Box>
                    {rewards?.follow_reward || 0}
                </Box>
            </Box>

            <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
                <Box display='flex' alignItems='center' fontSize='14px'>
                    <TwitterIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
                    {t("Twitter")}
                </Box>
                <Box>
                    {rewards?.twitter_reward || 0}
                </Box>
            </Box>
            
            <Box display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
                <Box display='flex' alignItems='center' fontSize='14px'>
                    <TwitterIcon sx={{ fontSize: '18px', bgcolor: '#1a1a1a', borderRadius: '50%', p: '0.5em', mr: '0.25em' }} />
                    {t("ShareOnTwitter")}
                </Box>
                <Box>
                    {rewards?.share_on_twitter_reward || 0}
                </Box>
            </Box>


            <Box sx={{ width: '100%', bgcolor: 'black' }}>
                <BottomNav value={0} />
            </Box>
            <Drawer anchor='bottom' open={showTonModal} onClose={() => {
                setShowTonModal(false)
            }}>
                <Paper>
                    <Box sx={{ p: 2, backgroundColor: '#0b0b0b' }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography color={'white'}>
                                Wallet
                            </Typography>
                            <CloseOutlined sx={{ color: 'white', ml: 'auto' }} onClick={() => setShowTonModal(false)} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button fullWidth sx={{
                                bgcolor: '#cc4b50', color: 'white', textTransform: 'none', width:'70%', mt: 1,
                                ':hover': {
                                    backgroundColor: '#dc5b60',
                                    color: 'white',
                                },
                            }}
                                onClick={async () => {
                                    setShowTonModal(false)
                                    await tonConnectUi.disconnect();
                                    tonConnectUi.openModal();
                                }}
                            >
                                <Typography fontWeight='bold' fontSize='14px'>
                                    Connect other wallet
                                </Typography>
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button fullWidth sx={{
                                bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%', mt: 2,
                                ':hover': {
                                    backgroundColor: 'white',
                                    color: 'black',
                                },
                            }}

                            >
                                <Typography fontWeight='bold' fontSize='14px'>
                                    {shorten(walletAddress)}
                                </Typography>
                            </Button>
                        </Box>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button fullWidth sx={{
                                bgcolor: 'white', color: 'black', textTransform: 'none', width: '70%', mt: 2,
                                ':hover': {
                                    backgroundColor: 'white',
                                    color: 'black',
                                },
                            }}
                                onClick={() => setShowTonModal(false)}
                            >
                                <Typography fontWeight='bold' fontSize='14px'>
                                    Close
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Drawer>
        </Box >
    )
}

export default Score
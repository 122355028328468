// src/redux/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const woooolSlice = createSlice({
    name: 'wooool',
    initialState: {
        balance: 0,
        tgId:null,
        username:null,
        inviteCode: null,
        registerYear:1,
        firstTime:null,
        reward:0
    },
    reducers: {
        updateUser:(state,action)=>{
            console.log('[debug]updateuser:',action.payload);
            state.username = action.payload.username;
            state.tgId = action.payload.id;
        },
        updateAccount: (state, action) => {
            console.log('updateAccount:',action.playload)
            state.balance = action.payload?.balance
            state.inviteCode = action.payload?.invite_code;
            state.registerYear = action.payload?.register_year??0;
            state.firstTime = !!action.payload?.is_create;
            state.reward = action.payload?.reward;
        },
    },
});

export const { updateAccount,updateUser } = woooolSlice.actions;

export default woooolSlice.reducer;

import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DogSVG from '../images/dog2.svg'
import BottomNav from '../components/BottomNav';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import ConfettiCanvas from '../components/Confetti';


const RisingStar = () => {
    const registerYear = useSelector(state=>state.wooool.registerYear)
    const navigate = useNavigate();
    const {t} =useTranslation()
    const [scoreLaunched,setScoreLaunched] = useState(false);
    useEffect(()=>{
        setTimeout(()=>{
            setScoreLaunched(true)
        },500);
    },[])
    return (
        <Box height='100vh'>
            <Box display='flex' justifyContent='space-around'>
                <Box border='1px solid white' width='45%' />
                <Box border='1px solid grey' width='45%' />
            </Box>
            <Box fontSize='30px' mb='1em' textAlign='center' pt='1em'>
                {t('star.title')}
            </Box>

            <Box fontSize='14px' mb='1em' textAlign='center' mt='2em'
            // border='1px solid white'
            >
                {t('star.joined')}
            </Box>
            <Box my='1em' justifyContent='center' display='flex' position='relative'>
                <img src={DogSVG} height='250px' alt='' />
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center' position='absolute' left='38%' top='28%'>
                <Box color='#CC4B50' fontSize='160px' fontWeight='bold'>{registerYear||1}</Box>

            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center' left='35%' top='55%'>
                <Box fontSize='30px'>
                     {registerYear<1?t("star.before"):t("star.ago")}
                </Box>
            </Box>

            <Box fontSize='14px' mb='0.1em' textAlign='center' mt='7em'
            >
                {t("star.number")} {Telegram?.WebApp?.initDataUnsafe?.user?.id}
            </Box>
            <Box fontSize='14px' mb='1em' textAlign='center'
            >
                {t("star.rank")}
            </Box>
            
                <Button
                fullWidth sx={{
                    bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '1em', textTransform: 'none',
                    ':hover': {
                        backgroundColor: '#cc4b50', 
                        color: 'white', 
                    },
                }} onClick={() => {
                    navigate('/amazing')
                }}>
                {scoreLaunched?<Typography fontWeight='bold' fontSize='14px'>{t("continue")}</Typography>:<CircularProgress/>}
            </Button>
            
            <ConfettiCanvas fireConfetti={scoreLaunched}/>
        </Box >
    )
}

export default RisingStar
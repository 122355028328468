import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from "react-router-dom";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
// import Home from './pages/Home';
// import About from './pages/About';
import CheckAccount from "./pages/CheckAccount";
import InvFriends from "./pages/InvFriends";
import RisingStar from "./pages/RisingStar";
import Box from "@mui/material/Box";
import Amazing from "./pages/Amazing";
import Score from "./pages/Score";
import WallOfFame from "./pages/WallOfFame";
import Start from './pages/Start';
import { useSelector, useDispatch } from 'react-redux';
import { updateAccount, updateUser } from "./redux/woooolSlice";
import { axiosInstance } from "./api";
import { useTranslation } from "react-i18next";


function App() {
    // const [inviteCode, setInviteCode] = useState('');
    // const [myInviteCode,setMyInviteCode] = useState('');
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const inviteCode = useSelector(state => state.wooool.inviteCode);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        try {
            Telegram.WebApp.expand();
            Telegram.WebApp.setBackgroundColor('#000000');
            Telegram.WebApp.disableVerticalSwipes();
        }
        catch (err) {
            console.error(err);
        }

    }, []);

    useEffect(() => {
        console.log('[debug]init data unsafe:', Telegram.WebApp.initDataUnsafe);
        if (Telegram.WebApp.initDataUnsafe) {

            (!!Telegram.WebApp.initDataUnsafe.user?.language_code) && i18n.changeLanguage(Telegram.WebApp.initDataUnsafe.user?.language_code?.slice(0, 2).toLowerCase());

            const param = Object.assign({}, Telegram.WebApp.initDataUnsafe.user);
            param && dispatch(updateUser(param));
            param.tg_id = param.id;
            param.hash = Telegram.WebApp.initDataUnsafe.hash;
            param.invite_code = Telegram.WebApp.initDataUnsafe.start_param;
            param.init_data = Telegram.WebApp.initData;
            param.is_premium = (param.is_premium || Telegram.WebApp.initDataUnsafe.is_premium) ? 1 : 0;
            // param.is_premium = 
            console.log('[debug]param:', param);


            axiosInstance
                .post("/user/create", param)
                .then((ret) => {
                    // setInviteCode(ret.data.invite_code);
                    console.log('[debug]user/create return:', ret.data);
                    dispatch(updateAccount(ret.data.data))
                })
                .catch(err => {
                    console.log('[error]user create:', err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [Telegram.WebApp.initDataUnsafe]);

    return (
        <TonConnectUIProvider
            manifestUrl="https://app.woool.bot/tonconnect-manifest.json"
            uiPreferences={{ theme: THEME.DARK }}
            walletsListConfiguration={{
                includeWallets: [
                    {
                        appName: "tonwallet",
                        name: "TON Wallet",
                        imageUrl: "https://wallet.ton.org/assets/ui/qr-logo.png",
                        aboutUrl: "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
                        universalLink: "https://wallet.ton.org/ton-connect",
                        jsBridgeKey: "tonwallet",
                        bridgeUrl: "https://bridge.tonapi.io/bridge",
                        platforms: ["chrome", "android"]
                    },
                    {
                        appName: "nicegramWallet",
                        name: "Nicegram Wallet",
                        imageUrl: "https://static.nicegram.app/icon.png",
                        aboutUrl: "https://nicegram.app",
                        universalLink: "https://nicegram.app/tc",
                        deepLink: "nicegram-tc://",
                        jsBridgeKey: "nicegramWallet",
                        bridgeUrl: "https://bridge.tonapi.io/bridge",
                        platforms: ["ios", "android"]
                    },
                    {
                        appName: "binanceTonWeb3Wallet",
                        name: "Binance Web3 Wallet",
                        imageUrl: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMwIiBoZWlnaHQ9IjMwIiBmaWxsPSIjMEIwRTExIi8+CjxwYXRoIGQ9Ik01IDE1TDcuMjU4MDYgMTIuNzQxOUw5LjUxNjEzIDE1TDcuMjU4MDYgMTcuMjU4MUw1IDE1WiIgZmlsbD0iI0YwQjkwQiIvPgo8cGF0aCBkPSJNOC44NzA5NyAxMS4xMjlMMTUgNUwyMS4xMjkgMTEuMTI5TDE4Ljg3MSAxMy4zODcxTDE1IDkuNTE2MTNMMTEuMTI5IDEzLjM4NzFMOC44NzA5NyAxMS4xMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMi43NDE5IDE1TDE1IDEyLjc0MTlMMTcuMjU4MSAxNUwxNSAxNy4yNTgxTDEyLjc0MTkgMTVaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0xMS4xMjkgMTYuNjEyOUw4Ljg3MDk3IDE4Ljg3MUwxNSAyNUwyMS4xMjkgMTguODcxTDE4Ljg3MSAxNi42MTI5TDE1IDIwLjQ4MzlMMTEuMTI5IDE2LjYxMjlaIiBmaWxsPSIjRjBCOTBCIi8+CjxwYXRoIGQ9Ik0yMC40ODM5IDE1TDIyLjc0MTkgMTIuNzQxOUwyNSAxNUwyMi43NDE5IDE3LjI1ODFMMjAuNDgzOSAxNVoiIGZpbGw9IiNGMEI5MEIiLz4KPC9zdmc+Cg==",
                        aboutUrl: "https://www.binance.com/en/web3wallet",
                        deepLink: "bnc://app.binance.com/cedefi/ton-connect",
                        bridgeUrl: "https://bridge.tonapi.io/bridge",
                        platforms: ["chrome", "safari", "ios", "android"],
                        universalLink: "https://app.binance.com/cedefi/ton-connect"
                    },
                    {
                        appName: "okxTonWallet",
                        name: "OKX Wallet",
                        imageUrl: "https://static.okx.com/cdn/assets/imgs/247/58E63FEA47A2B7D7.png",
                        aboutUrl: "https://www.okx.com/web3",
                        universalLink: "https://www.ouxyi.link/ul/uYJPB0",
                        bridgeUrl: "https://www.okx.com/tonbridge/discover/rpc/bridge",
                        jsBridgeKey: "okxTonWallet",
                        platforms: ["chrome", "safari", "firefox", "ios", "android"]
                    },
                    {
                        appName: "okxTonWalletTr",
                        name: "OKX TR Wallet",
                        imageUrl: "https://static.okx.com/cdn/assets/imgs/247/587A8296F0BB640F.png",
                        aboutUrl: "https://tr.okx.com/web3",
                        universalLink: "https://www.ouxyi.link/ul/uYJPB0?entityId=5",
                        bridgeUrl: "https://www.okx.com/tonbridge/discover/rpc/bridge",
                        jsBridgeKey: "okxTonWallet",
                        platforms: ["chrome", "safari", "firefox", "ios", "android"]
                    }
                ]
            }}
            actionsConfiguration={{
                twaReturnUrl: 'https://t.me/Wooool_bot/Wooool'
            }}
        >
            <Box bgcolor="black" color="white" height="100%" p="2em">
                <Router>
                    <Box>
                        {/* <Link to="/">Home </Link>
          
          <Link to="/invfriends">InvFriends </Link>
          
          <Link to="/risingstar">Rising Star </Link>
          
          <Link to="/amazing">Amazing </Link>
          
          <Link to="/score">Score </Link>
          
          <Link to="/walloffame">Wall Of Fame </Link> */}

                        <Routes>
                            <Route path="/" element={<Start />} />
                            <Route path="/checking" element={<CheckAccount />} />
                            <Route path="/invfriends" element={<InvFriends />} />
                            <Route path="/risingstar" element={<RisingStar />} />
                            <Route path="/amazing" element={<Amazing />} />
                            <Route path="/score" element={<Score />} />
                            <Route path="/walloffame" element={<WallOfFame />} />
                        </Routes>
                    </Box>
                </Router>
            </Box>
        </TonConnectUIProvider>
    );
}

export default App;

import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DogSVG from '../images/dog.svg'
import BottomNav from '../components/BottomNav';
import { useSelector } from 'react-redux';
import { Avatar, CircularProgress, Drawer, Paper } from '@mui/material';
import { axiosInstance, BOT_NAME, MINI_APP_NAME } from '../api';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from "react-copy-to-clipboard";
import CrossIcon from '@mui/icons-material/CloseOutlined';

const InvFriends = () => {
    const { t } = useTranslation();
    const tgId = useSelector(state => state.wooool.tgId)
    const [showInvite, setShowInvite] = useState(false);
    console.log('[debug]tgId:', tgId);

    const [friends, setFriends] = useState(null);
    useEffect(() => {
        if (tgId) {
            axiosInstance.post('/user/friends', {
                tg_id: tgId,
                page: 1,
                page_size: 10
            }).then(ret => {
                console.log('[debug]rewards:', ret.data?.data);
                setFriends(ret.data?.data?.friends);
            })
        }
    }, [tgId])

    const inviteCode = useSelector(state => { return state.wooool.inviteCode });

    const shareUrl = useMemo(() => {
        const url = encodeURIComponent(
            `https://t.me/${BOT_NAME}/${MINI_APP_NAME}?startapp=` + inviteCode
        );
        const text = encodeURIComponent("Let's woooool together");

        return `https://t.me/share/url?url=${url}&text=${text}`;
    }, [inviteCode])


    return (
        <Box height='100vh'
        // border='1px solid white'
        >
            <Box fontSize='30px' mb='1em' textAlign='center' pt='2em'>
                {t("inv.title")}
            </Box>
            <Box my='1em' justifyContent='center' display='flex'>
                <img src={DogSVG} alt='' />
            </Box>
            <Box fontSize='14px' mb='1em' textAlign='center' mt='2em'
            // border='1px solid white'
            >
                {t("inv.desc")}
            </Box>
            <Box>
                {friends?.map((f, idx) => {
                    return (
                        <Box key={'fk_' + idx} display='flex' justifyContent='space-between' alignItems='center' mt='1.5em'>
                            <Box display='flex' alignItems='center' fontSize='14px'>
                                <Avatar src={f.avatar_full_url || ''} />
                                {f.first_name + ' ' + f.last_name}
                            </Box>
                            <Box>
                                {f.to_parent_referrer_reward || 0}
                            </Box>
                        </Box>
                    )
                })}
            </Box>

            <Button
                onClick={() => {
                    //todo
                    if (!inviteCode) return;
                    setShowInvite(true);
                    // window.location.href = shareUrl
                }}
                fullWidth sx={{
                    mb: '300px',
                    bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '2em', textTransform: 'none',
                    ':hover': {
                        backgroundColor: '#cc4b50',
                        color: 'white',
                    },
                }} >
                {
                    inviteCode ? <Typography fontWeight='bold' fontSize='14px'>{t("inv.button")}</Typography> : <CircularProgress />
                }
            </Button>
            <Box sx={{ width: '100%', bgcolor: 'black' }}>
                <BottomNav value={2} />
            </Box>
            <Drawer anchor='bottom' open={showInvite} onClose={() => setShowInvite(false)}>
                <Paper>
                    <Box sx={{ p: 2, backgroundColor: 'black' }}>
                        <Box sx={{ display: 'flex' }}>
                            <Typography color={'white'}>{t('inv.button')}</Typography>
                            <CrossIcon sx={{ ml: 'auto', color: 'white' }} onClick={() => setShowInvite(false)} />
                        </Box>
                        <Box>
                            <CopyToClipboard text={`https://t.me/${BOT_NAME}/${MINI_APP_NAME}?startapp=` + inviteCode} onCopy={() => {
                                setShowInvite(false);
                            }}>
                                <Button
                                    fullWidth sx={{
                                        bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '2em', textTransform: 'none',
                                        ':hover': {
                                            backgroundColor: '#cc4b50',
                                            color: 'white',
                                        },
                                    }} >
                                    <Typography>
                                        {t('Copy invite link')}
                                    </Typography>
                                </Button>
                            </CopyToClipboard>
                        </Box>
                        <Box>
                            <Button
                                onClick={() => {
                                    if (!inviteCode) return;
                                    window.location.href = shareUrl;
                                    setShowInvite(false);
                                }}
                                fullWidth sx={{

                                    bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '2em', textTransform: 'none',
                                    ':hover': {
                                        backgroundColor: '#cc4b50',
                                        color: 'white',
                                    },
                                }} >
                                <Typography>
                                    {t('Share invite link')}
                                </Typography>
                            </Button>

                        </Box>

                    </Box>
                </Paper>
            </Drawer>
        </Box >
    )
}

export default InvFriends
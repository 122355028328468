import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DogSVG from '../images/dog2.svg'
// import DogGIF from '../images/goat.gif'
import DogGIF from '../images/start-1.svg'
import BottomNav from '../components/BottomNav';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';



const Start = () => {
    const [loading, setLoading] = useState(true);
    
    const navigate = useNavigate();
    const firstTime = useSelector(state => state.wooool.firstTime);
    console.log('[debug]firstTime:', firstTime);
    const { t } = useTranslation()
    useEffect(() => {
        
        setTimeout(() => {
            if (firstTime === false) {
                navigate('/score');
            }
            if ((!!firstTime) === true) {
                setLoading(false);
            }
        }, 500)
    }, [firstTime])

    return (
        <Box height='100vh'>


            <Box fontSize='14px' mb='1em' textAlign='center' mt='2em'
            >
                {t("start.welcome")}
            </Box>
            <Box my='1em' justifyContent='center' display='flex' position='relative'>
                <img src={DogGIF} height='250px' alt='' />
            </Box>


            <Box fontSize='14px' mb='1em' textAlign='center'
            // border='1px solid white'
            >
                {t("start.go")}
            </Box>
            {
                loading ?
                    <Box sx={{ display: 'flex', justifyContent: "center" }}>
                        <CircularProgress>

                        </CircularProgress>
                    </Box>
                    :

                    <Button
                        fullWidth sx={{
                            bgcolor: 'white', color: 'black', borderRadius: '0.5em', p: '0.75em', mt: '1em', textTransform: 'none',
                            ':hover': {
                                backgroundColor: '#cc4b50',
                                color: 'white',
                            },
                        }} onClick={() => {
                            navigate('/checking')
                        }}>
                        <Typography fontWeight='bold' fontSize='14px'>Let's wooool!</Typography>
                    </Button>

            }

            
        </Box >
    )
}

export default Start
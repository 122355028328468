import React, { useTransition } from 'react'

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BottomNav = (props) => {
    const navigate = useNavigate();

    const [value, setValue] = React.useState(props.value);
    const {t} = useTranslation()

    return (

        <Grid
            container
            position='fixed' width='100vw' left='0' bottom='0' bgcolor='black' height='10vh'
        // border='1px solid yellow'
        // position='absolute'
        >
            <Grid xs={12} item
            // border='1px solid white'
            >
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    sx={{ bgcolor: 'black', ...props }}
                >
                    <BottomNavigationAction
                        onClick={() => {
                            navigate('/score')
                        }}
                        sx={{
                            color: value === 0 ? 'white' : 'grey',
                            '&.Mui-selected': { color: 'white' },
                        }} label={t("navigation.Home")} icon={<HomeOutlinedIcon />} />
                    <BottomNavigationAction
                        onClick={() => {
                            navigate('/walloffame')
                        }}
                        sx={{
                            color: value === 1 ? 'white' : 'grey',
                            '&.Mui-selected': { color: 'white' },
                        }} label={t("navigation.Leaderboard")} icon={<LeaderboardOutlinedIcon />} />
                    <BottomNavigationAction onClick={() => {
                        navigate('/invfriends')
                    }} sx={{
                        color: value === 2 ? 'white' : 'grey',
                        '&.Mui-selected': { color: 'white' },
                    }} label={t("navigation.Friends")} icon={<PeopleOutlinedIcon />} />
                </BottomNavigation>
            </Grid>
        </Grid>

    )
}

export default BottomNav